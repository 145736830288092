<template>
  <div>
    <z-container cols="10">
      <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

      <v-form ref="formulario">
        <v-card>
          <v-toolbar flat color="white" dense>
            <h3 class="text-subtitle-2">Filtro</h3>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            
            <v-row dense>
              <v-col cols="6">
                <label class="label" for="">
                  Parceiro
                  <z-autocomplete
                    v-model="filtro.parceiro_id"
                    label="Parceiro"
                    item-value="id"
                    item-text="descricao"
                    single-line
                    clearable
                    hide-details
                    :items="parceiros"
                    @change="limparGuias"
                    @input="(value) => filtro.parceiro_id = value === null ? '' : value"
                  ></z-autocomplete>
                </label>
              </v-col>

              <v-col cols="6">
                <label class="label" for="">
                  Empresa
                  <z-autocomplete
                    v-model="filtro.empresa_id"
                    label="Empresa"
                    item-value="id"
                    item-text="descricao"
                    single-line
                    clearable
                    hide-details
                    :items="empresas"
                    @change="limparGuias"
                    @input="(value) => filtro.empresa_id = value === null ? '' : value"
                  ></z-autocomplete>
                </label>
              </v-col>

              <v-col cols="2">
                <label class="label" for="">
                  Tipo de Guia
                  <v-select
                    v-model="filtro.tipo_guia"
                    v-bind="$v.TEXT_FIELDS"
                    label="Tipo de Guia"
                    single-line
                    clearable
                    hide-details
                    multiple
                    attach
                    chips
                    :items="['SIMPLES', 'DARF_INSS', 'DARF_PIS', 'DARF_COFINS', 'DARF_CSLL', 'DARF_IRPJ']"
                  >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption" >
                      (+{{ filtro.tipo_guia.length - 1 }})
                    </span>
                  </template>
                </v-select>
                </label>
              </v-col>

              <v-col cols="2">
                <label class="label" for="">
                  Situação
                  <z-select
                    v-model="filtro.status"
                    single-line
                    hide-details
                    label="Situação"
                    :items="status"
                    @change="limparGuias"
                  ></z-select>
                </label>
              </v-col>

              <v-col cols="4">
                <v-row dense>
                  <v-col cols="6">
                    <label class="label" for="">
                      Competência Inicial
                      <z-select
                        v-model="filtro.mes_inicial"
                        label="Meses"
                        single-line
                        hide-details
                        :items="$globals.MESES"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                  
                  <v-col cols="6">
                    <label class="label" for="">
                      &nbsp;
                      <z-select
                        v-model="filtro.ano_inicial"
                        label="Anos"
                        single-line
                        hide-details
                        :items="anos"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                </v-row>
              
              </v-col>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="6">
                    <label class="label" for="">
                      Competência Final
                      <z-select
                        v-model="filtro.mes_final"
                        label="Meses"
                        single-line
                        hide-details
                        :items="$globals.MESES"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                  
                  <v-col cols="6">
                    <label class="label" for="">
                      &nbsp;
                      <z-select
                        v-model="filtro.ano_final"
                        label="Anos"
                        single-line
                        hide-details
                        :items="anos"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                </v-row>
              
              </v-col>

              <v-col cols="12" sm="2">
                <label class="label" for="">
                  Data Transferência
                  <z-date-picker-menu 
                    v-model="filtro.data_transferencia"
                    label="Data Transferência"
                    left 
                    single-line
                    hide-details
                    clearable
                  ></z-date-picker-menu>
                </label>
              </v-col>
            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="imprimir" :disabled="contas.length == 0">
              <v-icon left>mdi-printer</v-icon>
              Imprimir
            </v-btn>
            <v-btn color="primary" @click="filtrar" :loading="filtrando">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </z-container>

    <z-container cols="10">
      <z-cabecalho>Resultado ({{ contas.length }} contas)</z-cabecalho>

      <v-card min-height="200px">
        <v-card-text class="pa-0">
          <v-data-table 
            mobile-breakpoint="0" 
            hide-default-footer 
            disable-pagination 
            disable-sort 
            :headers="headers" 
            :items="contas"
          >
            <template v-slot:item="{ item: conta, index }">
              <tr>
                <td>{{ conta.guia_id }}</td>
                <td>{{ conta.guia.tipo_guia }}</td>
                <td>{{ conta.parceiro.nome }}</td>
                <td>{{ conta.guia.empresa.razao }}</td>
                <td class="text-center">{{ conta.guia.competencia_mes }}/{{ conta.guia.competencia_ano }}</td>
                <td class="text-center">{{ conta.data_pagamento | dataBR }}</td>
                <td class="text-center">{{ conta.data_transferencia | dataBR }}</td>
                <td class="text-right">{{ conta.valor_transferencia | currencyBR }}</td>
                <td class="text-no-wrap text-center">
                  <v-chip v-if="conta.pago" color="green" dark small>Pago</v-chip>
                  <v-chip v-else color="red" dark small>Em Aberto</v-chip>
                </td>
              </tr>
            </template>

            <!-- <template v-slot:body.append="{ headers }">
              <tr>
                <td colspan="7" class="text-right font-weight-bold">
                  {{ total_original | currencyBR }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ total_valor | currencyBR }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ total_comissao | currencyBR }}
                </td>
                <td>&nbsp;</td>
              </tr>
            </template> -->

            <!-- <template v-slot:item.opcoes="{item}">
              <v-btn icon @click="editarComissao(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="excluirComissao(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </z-container>
    <div style="margin-bottom: 200px"></div>
    
    <z-dialogo-impressao 
      ref="dialogoImpressao" 
      titulo="Impressão"
      :url-impressao="urlImpressao"
      @fechar="urlImpressao = ''"
    ></z-dialogo-impressao>
    
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { hoje, abrirJanela, range } from '@/app/helpers/utils';


import relatoriosApi from '@/app/services/relatoriosApi';
import config from '@/app/config/app';

import createAxios from '@/app/core/AxiosCore';
const axios = createAxios(config);

export default {
  name: 'PercentuaisParceiros',
  data() {
    return {
      filtro: {
        parceiro_id: '',
        empresa_id: '',
        status: '',
        mes_inicial: (new Date).getMonth() + 1,
        ano_inicial: (new Date).getFullYear(),
        mes_final: (new Date).getMonth() + 1,
        ano_final: (new Date).getFullYear(),
        tipo_guia: '',
        data_transferencia: ''
      },

      status: [
        { value: '', text: 'Qualquer' },
        { value: 'emaberto', text: 'Em Aberto' },
        { value: 'pagos', text: 'Pagos' },
      ],

      form_valido: true,

      headers: [
        { text: 'Guia', value: 'guia_id', width: '90px' },
        { text: 'Tipo', value: 'conta.guia.tipo_guia' },
        { text: 'Parceiro', value: 'conta.guia.parceiro.nome' },
        { text: 'Empresa', value: 'conta.guia.empresa.razao' },
        { text: 'Competência', width: '150px', align: 'center' },
        { text: 'Data Rec.', value: 'data_pagamento', align: 'center' },
        { text: 'Data Tran.', value: 'data_transferencia', align: 'center' },
        { text: 'Valor', value: 'valor_a_compensar', width: '150px', align: 'right' },
        { text: 'Situação', value: 'pago', width: '150px', align: 'center' },
      ],
      contas: [],
      filtrando: false,
      urlImpressao: '',
    };
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapGetters('listas', ['empresas', 'parceiros']),

    anos() {
      return range(new Date().getFullYear(), 2020);
    },
  },

  mounted() {
    this.setTitulo('Relatório - Contas a Pagar - Parceiros');
    // this.filtro.parceiro_id = 11;
    // this.filtro.data_inicial = this.$dayjs().startOf('month').format('YYYY-MM-DD');
    // this.filtro.data_final = hoje();

    this.$store.dispatch('listas/empresas');
    this.$store.dispatch('listas/parceiros');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),

    async filtrar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.filtrando = true;
        this.contas = await relatoriosApi.percentuaisParceiros(this.filtro);
      } catch (error) {
        this.$eventbus.notificarErro('', error);
        throw error;
      } finally {
        this.filtrando = false;
      }
    },

    onChangeDataInicial() {
      // this.filtro.data_final = this.filtro.data_inicial;
      this.limparGuias();
    },

    limparGuias() {
      this.contas = [];
    },

    imprimir() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      // abrirJanela(`${print_url}/relatorios/contaspagarparceiros/imprimir?` + new URLSearchParams(this.filtro).toString());

      setTimeout(async () => {
        await this.loadPDF();
        this.$refs.dialogoImpressao.abrir();
        // this.urlImpressao = `${print_url}/relatorios/contaspagarparceiros/imprimir?` + new URLSearchParams(this.filtro).toString();
      }, 300);
    },

    async loadPDF() {
      const pdf = await axios.get(`http://localhost:8000/relatorios/contaspagarparceiros/imprimir?parceiro_id=&empresa_id=&status=&mes_inicial=8&ano_inicial=2021&mes_final=8&ano_final=2024&tipo_guia=&data_transferencia=`, { responseType: 'blob' })
      .then(response => response.data);

      window.open(this.gerarBlobImpressao(pdf), "_blank");
      this.urlImpressao = this.gerarBlobImpressao(pdf);
    },

    gerarBlobImpressao(pdf) {
      return window.URL.createObjectURL(new Blob([pdf], {
        type: 'application/pdf'
      }));
    },
  },

  watch: {
    filtro: {
      handler(newValue, oldValue) {
        this.filtrar();
      },
      deep: true
    }
  },
};
</script>

<style lang="scss" scoped></style>
